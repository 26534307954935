import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import LoadingSpinner from "./components/LoadingSpinner";
import ProductDescription from "./web/product/ProductDescription";
import { AnimatedRoute } from "./AnimatedRoutes";
import { ProtectedRoute } from "./ProtectedRoute";
import NotFoundPage from "./components/NotFoundPage";
import RefurbishedHpLaptops from "./web/laptops/hp/Refurbished";
import SearchResultsPage from "./components/SearchResults";
import SearchBar from "./components/SearchBar";
import RefurbishedDellLaptops from "./web/laptops/dell/Refurbished";
import BrandNewLenovoLaptops from "./web/laptops/lenovo/BrandNew";
import RefurbishedLenovoLaptops from "./web/laptops/lenovo/Refurbished";

// Lazy loaded components
const AboutUs = lazy(() => import("./web/about/AboutUs"));
const HomePage = lazy(() => import("./web/homepage/HomePage"));
const Repairs = lazy(() => import("./web/repairs_solutions/Repairs"));
const Laptops = lazy(() => import("./web/category/laptop/Laptops"));
const Toners = lazy(() => import("./web/category/toners/Toners"));
const Printers = lazy(() => import("./web/category/printers/Printers"));
const Scanners = lazy(() => import("./web/category/scanners/Scanners"));
const Phone = lazy(() => import("./web/category/smartphones/Smarphones"));
const Desktop = lazy(() => import("./web/category/desktop/Desktop"));
const Accessories = lazy(() =>
    import("./web/category/accessories/Accessories")
);
const Cartridges = lazy(() => import("./web/category/cartridges/Cartridges"));
const Login = lazy(() => import("./web/login/Login"));
const Signup = lazy(() => import("./web/signup/Signup"));
const Dashboard = lazy(() => import("./web/admin/Dashboard"));
const AddProducts = lazy(() => import("./web/admin/Products/AddProducts"));
const CreateTag = lazy(() => import("./web/admin/createtags/CreateTag"));
const RequestPasswordReset = lazy(() => import("./components/RequestPassword"));
const ResetPassword = lazy(() => import("./components/ResetPassword"));
const Hp = lazy(() => import("./web/cartridges/Hp"));

//toners//
const TonersHp = lazy(() => import("./web/toners/Hp"));
const TonersEpson = lazy(() => import("./web/toners/Epson"));
const TonersBrothers = lazy(() => import("./web/toners/Brothers"));
const TonersCanon = lazy(() => import("./web/toners/Canon"));
const TonersKyocera = lazy(() => import("./web/toners/Kyocera"));

//printers//
const PrintersHp = lazy(() => import("./web/printers/Hp"));
const PrintersEpson = lazy(() => import("./web/printers/Epson"));
const PrintersBrothers = lazy(() => import("./web/printers/Brothers"));
const PrintersCanon = lazy(() => import("./web/printers/Canon"));
const PrintersKyocera = lazy(() => import("./web/printers/Kyocera"));

//cartridges//
const CartridgesHp = lazy(() => import("./web/cartridges/Hp"));
const CartridgesEpson = lazy(() => import("./web/cartridges/Epson"));
const CartridgesBrothers = lazy(() => import("./web/cartridges/Brothers"));
const CartridgesCanon = lazy(() => import("./web/cartridges/Canon"));
const CartridgesKyocera = lazy(() => import("./web/cartridges/Kyocera"));

//printers//
const ScannersHp = lazy(() => import("./web/scanners/Hp"));
const ScannersEpson = lazy(() => import("./web/scanners/Epson"));
const ScannersBrothers = lazy(() => import("./web/scanners/Brothers"));
const ScannersCanon = lazy(() => import("./web/scanners/Canon"));
const ScannersKyocera = lazy(() => import("./web/scanners/Kyocera"));

//laptops//
const BrandNewHpLaptops = lazy(() => import("./web/laptops/hp/BrandNew"));
const BrandNewDellLaptops = lazy(() => import("./web/laptops/dell/BrandNew"));

export default function AppRoutes() {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <HomePage />
                    </Suspense>
                }
            />
            <Route path="/" element={<SearchBar />} />
            <Route path="/search-results" element={<SearchResultsPage />} />
            <Route
                path="/about-us"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <AboutUs />
                    </Suspense>
                }
            />
            <Route
                path="/repairs-&-installation"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Repairs />
                    </Suspense>
                }
            />
            <Route
                path="/laptops"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Laptops />
                    </Suspense>
                }
            />
            <Route
                path="/toners"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Toners />
                    </Suspense>
                }
            />
            <Route
                path="/printers"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Printers />
                    </Suspense>
                }
            />
            <Route
                path="/scanners"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Scanners />
                    </Suspense>
                }
            />
            <Route
                path="/smartphones"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Phone />
                    </Suspense>
                }
            />
            <Route
                path="/desktops"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Desktop />
                    </Suspense>
                }
            />
            <Route
                path="/accessories"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Accessories />
                    </Suspense>
                }
            />
            <Route
                path="/cartridges"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Cartridges />
                    </Suspense>
                }
            />
            <Route
                path="/cartridges/hp"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Hp />
                    </Suspense>
                }
            />

            <Route
                path="/toners/hp"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <TonersHp />
                    </Suspense>
                }
            />
            <Route
                path="/toners/epson"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <TonersEpson />
                    </Suspense>
                }
            />
            <Route
                path="/toners/brother"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <TonersBrothers />
                    </Suspense>
                }
            />
            <Route
                path="/toners/canon"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <TonersCanon />
                    </Suspense>
                }
            />
            <Route
                path="/toners/kyocera"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <TonersKyocera />
                    </Suspense>
                }
            />

            {/* Printers */}
            <Route
                path="/printers/hp"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <PrintersHp />
                    </Suspense>
                }
            />
            <Route
                path="/printers/epson"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <PrintersEpson />
                    </Suspense>
                }
            />
            <Route
                path="/printers/brother"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <PrintersBrothers />
                    </Suspense>
                }
            />
            <Route
                path="/printers/canon"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <PrintersCanon />
                    </Suspense>
                }
            />
            <Route
                path="/printers/kyocera"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <PrintersKyocera />
                    </Suspense>
                }
            />

            <Route
                path="/cartridges/hp"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <CartridgesHp />
                    </Suspense>
                }
            />
            <Route
                path="/cartridges/epson"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <CartridgesEpson />
                    </Suspense>
                }
            />
            <Route
                path="/cartridges/brother"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <CartridgesBrothers />
                    </Suspense>
                }
            />
            <Route
                path="/cartridges/canon"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <CartridgesCanon />
                    </Suspense>
                }
            />
            <Route
                path="/cartridges/kyocera"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <CartridgesKyocera />
                    </Suspense>
                }
            />

            {/* Scanners */}
            <Route
                path="/scanners/hp"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <ScannersHp />
                    </Suspense>
                }
            />
            <Route
                path="/scanners/epson"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <ScannersEpson />
                    </Suspense>
                }
            />
            <Route
                path="/scanners/brother"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <ScannersBrothers />
                    </Suspense>
                }
            />
            <Route
                path="/scanners/canon"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <ScannersCanon />
                    </Suspense>
                }
            />
            <Route
                path="/scanners/kyocera"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <ScannersKyocera />
                    </Suspense>
                }
            />

            <Route
                path="/hp/brand-new"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <BrandNewHpLaptops />
                    </Suspense>
                }
            />
            <Route
                path="/hp/refurbished"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <RefurbishedHpLaptops />
                    </Suspense>
                }
            />
            <Route
                path="/dell/brand-new"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <BrandNewDellLaptops />
                    </Suspense>
                }
            />
            <Route
                path="/dell/refurbished"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <RefurbishedDellLaptops />
                    </Suspense>
                }
            />
            <Route
                path="/lenovo/brand-new"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <BrandNewLenovoLaptops />
                    </Suspense>
                }
            />
            <Route
                path="/lenovo/refurbished"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <RefurbishedLenovoLaptops />
                    </Suspense>
                }
            />
            <Route
                path="/login"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Login />
                    </Suspense>
                }
            />
            <Route
                path="/signup"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <Signup />
                    </Suspense>
                }
            />
            <Route
                path="/request-password-reset"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <RequestPasswordReset />
                    </Suspense>
                }
            />
            <Route
                path="/reset-password"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <ResetPassword />
                    </Suspense>
                }
            />
            <Route
                path="/product/:title/description"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <ProductDescription />
                    </Suspense>
                }
            />
            <Route
                path="/dashboard/*"
                element={
                    <Layout>
                        <ProtectedRoute>
                            <Suspense
                                fallback={
                                    <div>
                                        <LoadingSpinner />
                                    </div>
                                }
                            >
                                <Dashboard />
                            </Suspense>
                        </ProtectedRoute>
                    </Layout>
                }
            />
            <Route
                path="/products/add-products"
                element={
                    <AnimatedRoute>
                        <Layout>
                            <ProtectedRoute>
                                <Suspense
                                    fallback={
                                        <div>
                                            <LoadingSpinner />
                                        </div>
                                    }
                                >
                                    <AddProducts />
                                </Suspense>
                            </ProtectedRoute>
                        </Layout>
                    </AnimatedRoute>
                }
            />
            <Route
                path="/products/create-tags"
                element={
                    <AnimatedRoute>
                        <Layout>
                            <ProtectedRoute>
                                <Suspense
                                    fallback={
                                        <div>
                                            <LoadingSpinner />
                                        </div>
                                    }
                                >
                                    <CreateTag />
                                </Suspense>
                            </ProtectedRoute>
                        </Layout>
                    </AnimatedRoute>
                }
            />
            <Route
                path="*"
                element={
                    <Suspense
                        fallback={
                            <div>
                                <LoadingSpinner />
                            </div>
                        }
                    >
                        <NotFoundPage />
                    </Suspense>
                }
            />
        </Routes>
    );
}
